import React from 'react';
import { Row, Col } from 'antd';
import '../../assets/css/patrocinadores.css';

import plata_1 from '../../assets/images/primera_2025/patrocinadores/solensa.png';
import plata_2 from '../../assets/images/primera_2025/patrocinadores/AMANCO.png';
import plata_3 from '../../assets/images/primera_2025/patrocinadores/segurosmty.png';


import oro_1 from '../../assets/images/primera_2025/patrocinadores/cubimsa.png';
import oro_2 from '../../assets/images/primera_2025/patrocinadores/egosolar.png';
import oro_3 from '../../assets/images/primera_2025/patrocinadores/eltigre.png';
import oro_4 from '../../assets/images/primera_2025/patrocinadores/flonetworks.png';
import oro_5 from '../../assets/images/primera_2025/patrocinadores/gpobersa.png';
import oro_6 from '../../assets/images/primera_2025/patrocinadores/soprema.png';
import oro_7 from '../../assets/images/primera_2025/patrocinadores/construlita.png';
import oro_8 from '../../assets/images/primera_2025/patrocinadores/aceroform.png';

import oro_9 from '../../assets/images/primera_2025/patrocinadores/e-bemi.png';
import oro_10 from '../../assets/images/primera_2025/patrocinadores/sti.png';



let logos = [
    { tipo: 1, img_src: plata_2, nombre: 'orbai', estilo:{width:'80%'} },
    { tipo: 1, img_src: plata_1, nombre: 'millerknoll', estilo:{width:'100%'} },
    { tipo: 1, img_src: plata_3, nombre: 'segurosmty', estilo:{width:'100%'} },


    { tipo: 2, img_src: oro_1, nombre: '', estilo:{width:'80%'} },
    { tipo: 2, img_src: oro_2, nombre: '', estilo:{width:'100%'} },
]


const CarouselPatrocinadores = (params) => {


    const plata = logos.filter(item =>{
      if(item.tipo === 1){
      return true}
      else{
      return false}
    }).map((logo,index) => {
        return(
          <Col xs={{span:12, offset:0}} md={{span:7}}>
          <img src={logo.img_src} alt={logo.nombre} className="img_logo" style={logo.estilo}  />
          </Col>
        )
    });
    const oro = logos.filter(item =>{
      if(item.tipo === 2){
      return true}
      else{
      return false}
    }).map((logo,index) => {
        return(
          <Col xs={{span:12, offset:0}} md={{span:6}}>
          <img src={logo.img_src} alt={logo.nombre} className="img_logo" style={{ width:'80%'}}  />
          </Col>
        )
    });

    return (
      <>
      <Row justify="center" align={'middle'} className='back_blanco' style={{paddingBottom:'3%'}}>
        <Col xs={24} md={24}>
          <p className='textos_titulos_secciones color_azul'>PATROCINADORES</p>
        </Col>
        <Col xs={20} md={4}>
          <br /><br />
          <span className='patro_plata bold_font'>PLATINO</span>
          <br /><br />
        </Col>
        <Col xs={20} md={16}>
          <Row justify="start" align={'middle'}>
            {plata}
          </Row>
        </Col>
        <Col xs={22}>
          <hr  style={{border:'none', height:'1px', backgroundColor:'#cccccc'}} />
        </Col>
        <Col xs={20} md={4}>
        <br /><br />
          <span className='patro_oro'>ORO</span>
          <br /><br />
        </Col>
        <Col xs={20} md={16}>
          <Row justify="start" align={'middle'}>
            <Col xs={12} md={6}>
              <img src={oro_8} style={{width:'90%'}} />
            </Col>
          <Col xs={12} md={6}>
              <img src={oro_7} style={{width:'90%'}} />
            </Col>
            <Col xs={12} md={6}>
              <img src={oro_1} style={{width:'90%'}} />
            </Col>
            <Col xs={12} md={6}>
              <img src={oro_9} style={{width:'90%'}} />
            </Col>
            <Col xs={12} md={6}>
              <img src={oro_2} style={{width:'90%'}} />
            </Col>
            <Col xs={12} md={6}>
              <img src={oro_3} style={{width:'90%'}} />
            </Col>
            <Col xs={12} md={6}>
              <img src={oro_4} style={{width:'90%'}} />
            </Col>
            <Col xs={12} md={6}>
              <img src={oro_5} style={{width:'90%'}} />
            </Col>
            <Col xs={12} md={6}>
              <img src={oro_6} style={{width:'90%'}} />
            </Col>
            <Col xs={12} md={6}>
              <img src={oro_10} style={{width:'90%'}} />
            </Col>
          </Row>
        </Col>
        <Col xs={22} md={22}>
          <Row justify="center" align="middle">
          
          </Row>
        </Col>
      </Row>
      </>
    );

};

export default CarouselPatrocinadores;